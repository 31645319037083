(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpDatePicker', hpDatePickerDirective);
    hpDatePickerDirective.$inject = ['$timeout', 'uibDateParser', 'toaster'];
    function hpDatePickerDirective ($timeout, uibDateParser, toaster) {
        return {
            restrict: 'E',
            template: `
                <div class="inputWrapper form-control">
                    <button type="button" class="btnCalendar" ng-click="datepicker.open($event)" ng-disabled="to.disabled"></button>
                    <input type="text"
                        ng-model="datepicker.value"
                        class="inputCalendar"
                        placeholder="{{to.placeholder}}"
                        ng-click="datepicker.open($event)"
                        ng-change="datepicker.onChange()"
                        uib-datepicker-popup="{{datepicker.options.format}}"
                        is-open="datepicker.opened"
                        datepicker-options="datepicker.options" 
                        ng-disabled="to.disabled"
                    />
                </div>
            `,
            require: 'ngModel',
            replace: true,
            scope: {},
            link: function (scope, iElement, iAttrs, ngModelCtrl) {
                scope.datepicker = {
                    opened: false,
                    options: {
                        format: 'MM/dd/yyyy',
                        initDate: new Date()
                    }
                };
                scope.datepicker.open = function ($event) {
                    scope.datepicker.opened = !scope.datepicker.opened;
                };
                scope.datepicker.onChange = function () {
                    ngModelCtrl.$setViewValue(scope.datepicker.value);
                };

                ngModelCtrl.$formatters.push(function (modelValue) {
                    if (!modelValue) return;
                    return uibDateParser.parse(modelValue, 'M!/d!/yyyy');
                });
                ngModelCtrl.$parsers.push(function (viewValue) {
                    if (!viewValue) return null;
                    return uibDateParser.filter(viewValue, 'MM/dd/yyyy');
                });
                ngModelCtrl.$render = function () {
                    scope.datepicker.value = ngModelCtrl.$viewValue;
                };
            }
        };
    }
})(window.angular, window._);
